import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Bonding tools`}</h4>
    <p>{`Bonding tools include die attach tools (to connect dies to lead frames or substrates), wire bonders (to make interconnects between lead frames and die pads), and advanced interconnect tools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      